const initState = {
  lang: 'mk'
};

const languageReducer = (state = initState, action) => {
  switch (action.type) {
    case 'mk':
      return {
        ...state,
        lang: 'mk'
      };
    case 'en':
      return {
        ...state,
        lang: 'en'
      };
    default:
      return state;
  }
};

export default languageReducer;
