import { MK_TRANS } from '../actions/types';

const initState = {
  trans: [],
  mkTrans: {},
  error: null
};

const transReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_TRANSPARENCY':
      return {
        ...state,
        transparency: action.payload,
        error: null
      };
    case MK_TRANS:
      return {
        ...state,
        mkTrans: { ...action.payload }
      };
    case 'CREATE_TRANSPARENCY':
      console.log(state);
      return {
        ...state,
        transparency: [...state.transparency, action.payload]
      };
    case 'CREATE_TRANSPARENCY_ERROR':
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default transReducer;
