import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import {
  logoutUser,
  getUserData
} from './components/store/actions/authActions.js';
import { SET_AUTHENTICATED } from './components/store/actions/types.js';
import jwtDecode from 'jwt-decode';
import $ from 'jquery';

import AuthRoute from './util/AuthRoute.js';
import Navbar from './components/Navbar.js';
import LandingPage from './components/pages/LandingPage.js';
import Download from './components/Download.js';
import Footer from './components/Footer.js';
import SignIn from './components/pages/SignIn.js';
import NewsDetails from './components/pages/NewsDetails.js';
import SignUp from './components/pages/SignUp.js';

import AddProjectModal from './components/modals/AddProjectModal.js';
import AddCertModal from './components/modals/AddCertModal.js';
import AddCertEnModal from './components/modals/AddCertEnModal.js';
import AddEmployeeModal from './components/modals/AddEmployeeModal.js';
import UpdateEmployeeModal from './components/modals/UpdateEmployeeModal';
import UpdateEmployeeEnModal from "./components/modals/UpdateEmployeeEnModal";
import AddEmployeeEnModal from './components/modals/AddEmployeeEnModal.js';
import AddTransModal from './components/modals/AddTransModal.js';
import AddTransEnModal from './components/modals/AddTransEnModal.js';
import DeleteUserModal from './components/modals/DeleteUserModal.js';

import axios from 'axios';

import './Index.css';

axios.defaults.baseURL =
  'https://europe-west1-ideja-plus.cloudfunctions.net/api';

const base = '/:locale(mk|en)?';

export const stripLocale = (pathname, locale) => {
  if (!locale) {
    return pathname;
  }

  return pathname.replace(`/${locale}`, '');
};

const App = ({ store }) => {
  useEffect(() => {
    $(function () {
      $('[data-toggle="tooltip"]')
        .tooltip();
    });
  }, []);

  const token = localStorage.FBIdToken;
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      store.dispatch(logoutUser());
    } else {
      store.dispatch({ type: SET_AUTHENTICATED });
      axios.defaults.headers.common['Authorization'] = token;
      store.dispatch(getUserData());
    }
  }

  return (
    <>
      <BrowserRouter>
        <AddCertModal/>
        <AddCertEnModal/>
        <DeleteUserModal/>
        <AddTransModal/>
        <AddTransEnModal/>
        <AddEmployeeModal/>
        <AddEmployeeEnModal/>
        <UpdateEmployeeModal/>
        <UpdateEmployeeEnModal/>
        <AddProjectModal/>
        <header className="page-header">
          <Navbar/>
        </header>
        <main role="main">
          <Switch>
            <Redirect exact from="/" to="/mk"/>
            <Route exact path={base} component={LandingPage}/>
            <Route path="/project/:id" component={NewsDetails}/>
            <AuthRoute path="/login" component={SignIn}/>
            <Route path="/signup" component={SignUp}/>
          </Switch>
        </main>
        <Download/>
        <Footer/>
      </BrowserRouter>
    </>
  );
};

export default App;
