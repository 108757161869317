import React, { useState } from 'react';
import { db, auth } from '../../config/firebaseConfig.js';

const SignUp = () => {
  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');

  const handleEmail = e => {
    setSignupEmail(e.target.value);
  };

  const handlePassword = e => {
    setSignupPassword(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    auth
    .createUserWithEmailAndPassword(signupEmail, signupPassword)
    .then(cred => {
      return db.collection('users').doc(cred.user.uid).set({
        bio: 'value of some field with curly brackets e.target.value'
      });
    });
  };

  return (
    <div className="container" style={{ marginTop: '240px' }}>
      <div className="section-sign-up">
        <form className="sign-up-form" onSubmit={e => handleSubmit(e)}>
          <h5 className="text-white">Sign Up</h5>
          <div className="input-field">
            <label htmlFor="signup-email">Email</label>
            <input
              id="signup-email"
              type="email"
              onChange={e => handleEmail(e)}
            />
          </div>
          <div className="input-field">
            <label htmlFor="signup-password">Password</label>
            <input
              id="signup-password"
              type="password"
              onChange={e => handlePassword(e)}
            />
          </div>
          <button type="submit" className="btn btn-success">
            Sign up
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
