import React from 'react';
import Moment from 'react-moment';
import I18n from '../../i18n';

const NewsSummary = ({ project }) => {
  return (
    <div className="card card-news" data-background-color="pink">
      <div className="card-header card-header-news">
        <h2 className="card-title text-center" id="#headingOne">
          {' '}
          {project.title}
        </h2>
      </div>
      <div className="card-body card-body-news">
        <p>{project.content}</p>
      </div>
      <div className="card-footer">
        <span style={{ float: 'left' }}>
          <span style={{ color: '#DCDCDC' }}>
            {' '}
            <I18n t="postedBy1" />
          </span>{' '}
          <I18n t="postedBy2" />
        </span>
        <span style={{ float: 'right' }} className="grey-text">
          <Moment format="DD / MM / YYYY">{project.createdAt.toDate()}</Moment>
        </span>
      </div>
    </div>
  );
};

export default NewsSummary;
