import React, { createRef } from 'react';
import I18n from '../../i18n';
import $ from 'jquery';

import pic1 from '../../assets/img/office/office-5-caro.jpg';
import pic2 from '../../assets/img/office/office-6-caro.jpg';
import pic3 from '../../assets/img/office/office-7-caro.jpg';
import pic4 from '../../assets/img/office/office-8-caro.jpg';
import pic5 from '../../assets/img/office/office-9-caro.jpg';
import pic6 from '../../assets/img/office/office-10-caro.jpg';
import pic7 from '../../assets/img/office/office-11-caro.jpg';
import pic8 from '../../assets/img/office/office-12-caro.jpg';

const Services = () => {
  let pageHeader = createRef();

  const handleSubmit = () => {
    $('#servicesCarousel').carousel('pause');
  };

  return (
    <div id="3" className="section section-services">
      <div id="services-scroll" className="container text-center">
        <div className="title">
          <h1 className="h1-seo" style={{ paddingBottom: '40px' }}>
            <I18n t="serv" />
          </h1>
        </div>

        <div className="row justify-content-around">
          {/* Carousel */}
          <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-8">
            <div
              id="servicesCarousel"
              className="carousel slide"
              data-ride="carousel"
              ref={pageHeader}
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#servicesCarousel"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li data-target="#servicesCarousel" data-slide-to="1"></li>
                <li data-target="#servicesCarousel" data-slide-to="2"></li>
                <li data-target="#servicesCarousel" data-slide-to="3"></li>
                <li data-target="#servicesCarousel" data-slide-to="4"></li>
                <li data-target="#servicesCarousel" data-slide-to="5"></li>
                <li data-target="#servicesCarousel" data-slide-to="6"></li>
                <li data-target="#servicesCarousel" data-slide-to="7"></li>
              </ol>

              <div className="carousel-inner h-100" role="listbox">
                <div className="carousel-item active">
                  <img
                    src={pic1}
                    className="d-block w-100"
                    alt="Ревизија на финансиски извештаи на средни и големи субјекти, Акционерски друштва, ДОО и ДООЕЛ"
                  />
                  <div className="carousel-caption d-md-block rev">
                    <h4 className="rev-h4">
                      <I18n t="serv rev" />
                    </h4>
                    <p>
                      <I18n t="serv rev 2" />
                      <br></br>
                      <I18n t="serv rev 3" />
                      <br></br>
                      <I18n t="serv rev 4" />
                      <br></br>
                      <I18n t="serv rev 5" />
                      <br></br>
                      <I18n t="serv rev 6" />
                      <br></br>
                      <I18n t="serv rev 7" />
                      <br></br>
                      <I18n t="serv rev 8" />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={pic2}
                    className="d-block w-100"
                    alt="Сметководство. Финансиска, материјална и архивска евиденција. Пресметка на интегрирана бруто плата и кадровска евиденција. Пресметка на сите видови даноци."
                  />
                  <div className="carousel-caption d-md-block acc">
                    <h4 className="acc-h4">
                      <I18n t="accounting serv" />
                    </h4>
                    <p>
                      <I18n t="acc serv 1" />
                      <br />
                      <br />
                      <I18n t="acc serv 2" />
                      <br />
                      <I18n t="acc serv 3" />
                      <br />
                      <I18n t="acc serv 4" />
                      <br />
                      <I18n t="acc serv 5" />
                      <br />
                      <I18n t="acc serv 6" />
                      <br />
                      <I18n t="acc serv 7" />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={pic3}
                    className="d-block w-100"
                    alt="Регистрација на трговско друштво ДОО, ДООЕЛ, АД, ЈТД, Трговец Поединец, Здруженија и Фондации."
                  />
                  <div className="carousel-caption d-md-block reg">
                    <h4 className="reg-h4">
                      <I18n t="comp reg" />
                    </h4>
                    <p>
                      <I18n t="comp reg 1" />
                      <br />
                      <br />
                      <I18n t="comp reg 2" />
                      <br />
                      <I18n t="comp reg 3" />
                      <br />
                      <I18n t="comp reg 4" />
                      <br />
                      <I18n t="comp reg 5" />
                      <br />
                      <I18n t="comp reg 6" />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={pic4}
                    className="d-block w-100"
                    alt="Изработува Бизнис(деловен) План и Инвестициони Програми, согласно Методологијата на Светска банка, МБПР, АППРМ, Национална програма за рураелн развој и ИПАРД програмата."
                  />
                  <div className="carousel-caption d-md-block bus">
                    <h4 className="bus-h4">
                      <I18n t="business plan title" />
                    </h4>
                    <p>
                      <I18n t="business plan text" />
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={pic5}
                    className="d-block w-100"
                    alt="Работиме на Проценка на капиталот на компаниите за целите за продажба на цело претпријатие, продажба или залог на поединечни средства или опрема, Основна главнина при основање на трговски друштва."
                  />
                  <div
                    className="carousel-caption d-md-block appr"
                    style={{ height: '80%' }}
                  >
                    <h4 className="appr-h4">
                      <I18n t="app title serv" />
                    </h4>
                    <br />
                    <p>
                      <I18n t="app 1" />
                      <br />
                      <br />
                      <I18n t="app 2" />
                      <br />
                      <I18n t="app 3" />
                      <br />
                      <I18n t="app 4" />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={pic6}
                    className="d-block w-100"
                    alt="Ги вршиме сите видови вештачења за граѓански и стопански спорови."
                  />
                  <div
                    className="carousel-caption d-md-block"
                    style={{ height: '80%' }}
                  >
                    <h4>
                      <I18n t="expertise serv" />
                    </h4>
                    <p>
                      <I18n t="expertise serv 1" />
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={pic7}
                    className="d-block w-100"
                    alt="Едуцирани Инвестициони Советници, со овластување за Инвестиционен Советник од Комисијата за Хартии од Вредност."
                  />
                  <div
                    className="carousel-caption d-md-block inve"
                    style={{ height: '99%' }}
                  >
                    <h4 className="inve-h4">
                      <I18n t="inv couns" />
                    </h4>
                    <p>
                      <span style={{ color: '#f76e9c', fontWeight: '600' }}>
                        <I18n t="who" /> ?
                      </span>
                      <br />
                      <I18n t="who 1" />
                      <br />
                      <span style={{ color: '#f76e9c', fontWeight: '600' }}>
                        <I18n t="how" /> ?
                      </span>
                      <br />
                      <I18n t="how 1" />
                    </p>
                    <br />
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={pic8}
                    className="d-block w-100"
                    alt="Брокерски совети. Инвестирајте и тргувајте со хартии од вредност за поголем принос на Вашите средства, преку едноставни, брзи и доверливи услуги."
                  />
                  <div
                    className="carousel-caption d-md-block brok"
                    style={{ height: '97%' }}
                  >
                    <h4 className="brok-h4">
                      <I18n t="bro adv" />
                    </h4>
                    <p>
                      <I18n t="bro adv 1" />
                    </p>
                    <p>
                      <span style={{ color: '#f76e9c', fontWeight: '600' }}>
                        <I18n t="vmro1" /> ?
                      </span>{' '}
                      <I18n t="bro adv 2" /> <br />
                      <span style={{ color: '#f76e9c', fontWeight: '600' }}>
                        <I18n t="vmro2" /> ?
                      </span>{' '}
                      <I18n t="bro adv 3" /> <br />
                      <span style={{ color: '#f76e9c', fontWeight: '600' }}>
                        <I18n t="vmro3" /> ?
                      </span>{' '}
                      <I18n t="bro adv 4" />
                    </p>
                    <br />
                    <br />
                  </div>
                </div>
              </div>

              <a
                className="carousel-control-prev"
                href="#servicesCarousel"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#servicesCarousel"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
          <div className="w-100 d-sm-none"></div>
          {/* Services list */}
          <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <ol className="carousel-indicators-list text-white">
              <li
                data-target="#servicesCarousel"
                data-slide-to="0"
                className="active"
                onClick={() => handleSubmit()}
              >
                <I18n t="serv rev" />
              </li>
              <li
                data-target="#servicesCarousel"
                data-slide-to="1"
                onClick={() => handleSubmit()}
              >
                <I18n t="acco" />
              </li>
              <li
                data-target="#servicesCarousel"
                data-slide-to="2"
                onClick={() => handleSubmit()}
              >
                <I18n t="copm regi" />
              </li>
              <li
                data-target="#servicesCarousel"
                data-slide-to="3"
                onClick={() => handleSubmit()}
              >
                <I18n t="busi plan" />
              </li>
              <li
                data-target="#servicesCarousel"
                data-slide-to="4"
                onClick={() => handleSubmit()}
              >
                <I18n t="appr" />
              </li>
              <li
                data-target="#servicesCarousel"
                data-slide-to="5"
                onClick={() => handleSubmit()}
              >
                <I18n t="expertise" />
              </li>
              <li
                data-target="#servicesCarousel"
                data-slide-to="6"
                onClick={() => handleSubmit()}
              >
                <I18n t="inve coun" />
              </li>
              <li
                data-target="#servicesCarousel"
                data-slide-to="7"
                onClick={() => handleSubmit()}
              >
                <I18n t="brok advi" />
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
