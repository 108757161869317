import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import projectReducer from './projectReducer.js';
import certReducer from './certReducer.js';
import transReducer from './transReducer.js';
import authReducer from './authReducer.js';
import uiReducer from './uiReducer.js';
import empReducer from './empReducer.js';
import languageReducer from './languageReducer.js';

export default combineReducers({
  lang: languageReducer,
  project: projectReducer,
  UI: uiReducer,
  emp: empReducer,
  cert: certReducer,
  transparency: transReducer,
  auth: authReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});
