import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { db } from '../../config/firebaseConfig.js';

const UpdateEmployeeEnModal = ({ emp }) => {
  const [values, setValues] = useState({
    firstName: emp?.en?.firstName || "",
    lastName: emp?.en?.lastName || "",
    subText: emp?.en?.subtext || ""
  });

  useEffect(() => {
    if (emp && emp.en) {
      setValues({
        firstName: emp.en.firstName,
        lastName: emp.en.lastName,
        subtext: emp.en.subtext
      });
    }
  }, [emp]);

  const handleFieldChange = e => {
    const { name, value } = e.target;

    setValues(pre => ({
      ...pre,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      return await db.collection('employee')
        .doc(emp.docId)
        .update({
          ...emp,
          en: {
            ...values,
            partner: emp.partner,
            phone: emp.phone,
            emailOne: emp.emailOne,
            emailTwo: emp.emailTwo,
            linkedin: emp.linkedin,
            imageUrl: emp.imageUrl,
            cvLink: emp.cvLink,
            cvLink2: emp.cvLink2,
            createdAt: emp.createdAt
          },
          docId: emp.docId
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container">
      <div
        id="update-employee-en-modal"
        className="modal fade"
        role="dialog"
        aria-labelledby="updateEmployeeEnModal"
        aria-hidden="true"
        tabIndex="-1"
        style={{ fontFamily: 'Montserrat' }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ minWidth: '920px' }}
        >
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'pink' }}>
              <img
                src={require('../../assets/img/logo.png')}
                height="32"
                width="32"
                alt="logo"
                style={{ marginRight: '20px' }}
              />
              <h5 className="modal-title" id="updateEmployeeEnModal">
                Update an Employee
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center" style={{ height: '780px' }}>
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <h4>English Version</h4>
                  <br/>
                  <div className="row">
                    {/* First Name */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="firstNameEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          First Name
                        </label>
                        <input
                          className="form-control"
                          id="firstNameEN"
                          type="text"
                          name="firstName"
                          value={values.firstName || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Last Name */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="lastNameEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Last Name
                        </label>
                        <input
                          className="form-control"
                          id="lastNameEN"
                          type="text"
                          name="lastName"
                          value={values.lastName || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Subtext */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="subtextEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Subtext
                        </label>
                        <input
                          className="form-control"
                          id="subtextEN"
                          type="text"
                          name="subtext"
                          value={values.subtext || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Phone */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="phoneEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Phone
                        </label>
                        <input
                          className="form-control"
                          id="phoneEN"
                          type="text"
                          name="add-employee-en-phone"
                          value={emp.phone || ''}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Email 1 */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="emailOneEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Email 1
                        </label>
                        <input
                          className="form-control"
                          id="emailOneEN"
                          type="text"
                          name="add-employee-en-emailOne"
                          value={emp.emailOne || ''}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Email 2 */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="emailTwoEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Email 2 (Optional)
                        </label>
                        <input
                          className="form-control"
                          id="emailTwoEN"
                          type="text"
                          name="add-employee-en-emailTwo"
                          value={emp.emailTwo || ''}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Linkedin */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="linkedinEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Linkedin
                        </label>
                        <input
                          className="form-control"
                          id="linkedinEN"
                          type="text"
                          name="add-employee-en-linkedin"
                          value={emp.linkedin || ''}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Partner */}
                    <div className="col-4 mt-5 ml-2">
                      <div className="input-group">
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="partnerCheckEN"
                            value={emp.partner || ''}
                            disabled
                          />
                          <label
                            className="form-check-label"
                            htmlFor="partnerCheckEN"
                          >
                            Ideja Plus Partner
                          </label>
                        </div>
                      </div>
                    </div>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div
                      className="modal-footer col-12"
                      style={{ backgroundColor: 'pink' }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        data-dismiss="modal"
                        style={{ color: 'white' }}
                      >
                        Back
                      </button>

                      <button type="submit" className="btn btn-pink">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  emp: state.emp.mkEmp
});

export default connect(mapStateToProps)(UpdateEmployeeEnModal);
