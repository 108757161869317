import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle as icon1 } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle as icon2 } from '@fortawesome/free-solid-svg-icons';
import I18n from '../../i18n';
import { withRouter } from 'react-router-dom';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: '',
      name: '',
      senderEmail: '',
      email: 'idejaplusrevizija@yahoo.com'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleSenderEmail = this.handleSenderEmail.bind(this);
  }

  render() {
    const { location } = this.props;

    return (
      <form id="contact-form" className="test-mailing">
        <div className="form-group">
          <div className="input-group input-lg">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon icon={icon1} />
              </span>
            </div>
            <input
              placeholder={I18n.getTranslation(location, 'placename')}
              type="text"
              className="form-control"
              aria-label="Dollar amount (with dot and two decimal places)"
              onChange={this.handleName}
              value={this.state.name}
            ></input>
          </div>
        </div>
        <div className="form-group">
          <div className="input-group input-lg">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon icon={icon2} />
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="emailHelp"
              placeholder={I18n.getTranslation(location, 'placeemail')}
              onChange={this.handleSenderEmail}
              value={this.state.senderEmail}
            ></input>
          </div>
        </div>
        <div className="form-group">
          <div className="textarea-container">
            <textarea
              id="test-mailing"
              name="test-mailing"
              cols="80"
              placeholder={I18n.getTranslation(location, 'placemessage')}
              rows="4"
              onChange={this.handleChange}
              className="form-control"
              required
              value={this.state.feedback}
            ></textarea>
          </div>
          <div className="send-button">
            <button
              type="submit"
              className="btn btn-submit btn-primary"
              onClick={this.handleSubmit}
            >
              <I18n t="submit" />
            </button>
          </div>
        </div>
      </form>
    );
  }

  handleChange(event) {
    this.setState({ feedback: event.target.value });
  }

  handleName(e) {
    this.setState({ name: e.target.value });
  }

  handleSenderEmail(e) {
    this.setState({ senderEmail: e.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const templateId = 'template_pPZ8Pvih';

    this.sendFeedback(templateId, {
      message_html: this.state.feedback,
      from_name: this.state.name,
      reply_to: this.state.email
    });

    this.setState({
      feedback: '',
      name: '',
      senderEmail: ''
    });
  }

  sendFeedback(templateId, variables) {
    window.emailjs
      .send('gmail', templateId, variables)
      .then(res => {
        console.log('Email successfully sent!');
      })
      .catch(err => console.error(err));
  }
}

export default withRouter(Form);
