import React, { useLayoutEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import SignedInLinks from './SignedInLinks.js';
import I18n from '../i18n';
import $ from 'jquery';

import logo from '../assets/img/ideja-logo.png';

const Navbar = props => {
  const { auth, profile, history } = props;
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    $('a[href^="#"]').bind('click', function (e) {
      e.preventDefault();

      let target = $(this).attr('href');

      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $(target).offset().top
          },
          600,
          function () {
            window.location.hash = target;
          }
        );

      return false;
    });
  }, []);

  $(window)
    .scroll(function () {
      let scrollDistance = $(window).scrollTop();

      $('.section').each(function (i) {
        if ($(this).position().top <= scrollDistance) {
          $('.navbar a.paint').removeClass('paint');
          $('.navbar a').eq(i).addClass('paint');
        }
      });
    })
    .scroll();

  const changeLanguage = lang => {
    history.push(`/${lang}`);
    dispatch({
      type: lang
    });
  };

  const links = auth.uid ? <SignedInLinks profile={profile} /> : null;

  return (
    <nav className="navbar navbar-expand-xl navbar-dark fixed-top">
      <div className="container-fluid">
        <NavLink to="/" className="navbar-brand">
          <img src={logo} alt="" /> <I18n t="ideja-logo-navbar" />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto text-center">
            <li className="nav-item active">
              <ScrollLink
                className="nav-link"
                to="about-us-scroll"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <I18n t="About us title" />{' '}
                <span className="sr-only">(current)</span>
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="our-team-scroll"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <I18n t="team" />
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link text-white"
                to="services-scroll"
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <I18n t="services" />
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="certificates-scroll"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <I18n t="certificates" />
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="transparency-scroll"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <I18n t="transparency" />
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="news-scroll"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <I18n t="news" />
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link text-white"
                to="contact-scroll"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <I18n t="contact" />
              </ScrollLink>
            </li>
            <li className="nav-item">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => changeLanguage('mk')}
              >
                <img alt="" src={require('../assets/img/flags/MK.png')}></img>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => changeLanguage('en')}
              >
                <img alt="" src={require('../assets/img/flags/GB.png')}></img>
              </button>
            </li>
            {links}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(withRouter(Navbar));
