import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
import { db } from '../../config/firebaseConfig.js';
import CertificatesList from './CertificatesList.js';
import AddCertButton from '../buttons/AddCertButton.js';
import Loader from '../pages/Loader.js';
import I18n from '../../i18n';

const Certificates = ({ certs, auth, location }) => {
  const dispatch = useDispatch();
  const addCertButton = auth.uid ? <AddCertButton /> : null;

  const handleDelete = cert => {
    const id = cert.id;

    db
      .collection('certificates')
      .doc(id)
      .delete()
      .then(() => {
        dispatch({
          type: 'DELETE_CERT',
          payload: id
        });
      })
      .catch(err => {
        dispatch({
          type: 'DELETE_CERT_ERROR',
          payload: err.message
        });
      });
  };

  if (!certs) {
    return <Loader />;
  }

  const mkCert1 = certs.map(cert => (
    <li key={cert.id}>
      {cert.column === '1' ? (
        <CertificatesList cert={cert} auth={auth} onDelete={handleDelete} />
      ) : null}
    </li>
  ));
  const mkCert2 = certs.map(cert => (
    <li key={cert.id}>
      {cert.column === '2' ? (
        <CertificatesList cert={cert} auth={auth} onDelete={handleDelete} />
      ) : null}
    </li>
  ));
  const mkCert3 = certs.map(cert => (
    <li key={cert.id}>
      {cert.column === '3' ? (
        <CertificatesList cert={cert} auth={auth} onDelete={handleDelete} />
      ) : null}
    </li>
  ));

  const enCert1 = certs.map(cert => (
    <li key={cert.id}>
      {cert.column === '1' ? (
        <CertificatesList cert={cert.en} auth={auth} onDelete={handleDelete} />
      ) : null}
    </li>
  ));
  const enCert2 = certs.map(cert => (
    <li key={cert.id}>
      {cert.column === '2' ? (
        <CertificatesList cert={cert.en} auth={auth} onDelete={handleDelete} />
      ) : null}
    </li>
  ));
  const enCert3 = certs.map(cert => (
    <li key={cert.id}>
      {cert.column === '3' ? (
        <CertificatesList cert={cert.en} auth={auth} onDelete={handleDelete} />
      ) : null}
    </li>
  ));

  return (
    <div id="4" className="section section-certificates">
      <div id="certificates-scroll" className="container text-center">
        <h1 className="h1-seo">
          <I18n t="cert2" />
        </h1>
        <div className="row justify-content-start">
          {addCertButton}
          <div className="col col-md-4 text-left">
            <ul className="certificates-ul">
              {location.pathname === '/mk' ? mkCert1 : enCert1}
            </ul>
          </div>
          <div className="col col-md-4">
            <ul className="certificates-ul">
              {location.pathname === '/mk' ? mkCert2 : enCert2}
            </ul>
          </div>
          <div className="col col-md-4">
            <ul className="certificates-ul">
              {location.pathname === '/mk' ? mkCert3 : enCert3}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    certs: state.firestore.ordered.certificates,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'certificates', orderBy: 'row' }])
)(withRouter(Certificates));
