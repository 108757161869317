import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createProject } from '../store/actions/projectActions.js';
import I18n from '../../i18n';

class CreateProject extends Component {
  state = {
    title: '',
    content: ''
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.createProject(this.state);
  };

  render() {
    return (
      <div className="container">
        <div
          className="modal fade"
          id="add-news-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addProjectModal"
          aria-hidden="true"
          style={{ fontFamily: 'Montserrat' }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ maxWidth: '800px' }}
          >
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: 'pink' }}>
                <img
                  src={require('../../assets/img/logo.png')}
                  height="32"
                  width="32"
                  alt="logo"
                  style={{ marginRight: '20px' }}
                />
                <h5 className="modal-title" id="addProjectModal">
                  <I18n t="createNewsProject"/>
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                <form onSubmit={this.handleSubmit}>
                  <div className="container-fluid">
                    <div className="form-group">
                      <label htmlFor="titlePro">Наслов</label>
                      <input
                        className="form-control"
                        id="titlePro"
                        type="text"
                        name="add-news-title"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="content">Напиши текст</label>
                      <textarea
                        className="form-control"
                        id="content"
                        name="add-news-area"
                        rows="10"
                        onChange={this.handleChange}
                        style={{ maxHeight: '400px' }}
                      />
                    </div>
                    <div
                      className="modal-footer"
                      style={{ backgroundColor: 'pink' }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        data-dismiss="modal"
                        style={{ color: 'white' }}
                      >
                        <I18n t="exit"/>
                      </button>
                      <button type="submit" className="btn btn-pink">
                        <I18n t="postProject"/>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createProject: project => dispatch(createProject(project))
  };
};

export default connect(null, mapDispatchToProps)(CreateProject);
