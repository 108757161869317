import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { deleteProject } from '../store/actions/projectActions.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import I18n from '../../i18n';

const NewsDetails = props => {
  const { project, dispatchDeleteProject } = props;
  const history = useHistory();

  const submitButton = e => {
    history.goBack();
    dispatchDeleteProject(e, props.id);
  };

  if (!project) {
    return (
      <div className="container">
        <p>Loading project..</p>
      </div>
    );
  } else {
    return (
      <div
        className="section section-project-details"
        style={{ marginTop: '240px' }}
      >
        <div className="container">
          <div className="row text-center">
            <div className="col col-12">
              <div
                className="card"
                style={{ fontSize: '1.3rem' }}
                data-background-color="pink"
              >
                <div className="card-content">
                  <h2
                    className="mb-0 d-flex justify-content-between align-items-center card-title"
                    id="#headingOne"
                    style={{ fontSize: '2rem' }}
                  >
                    <span className="text-center"></span>
                    {project.title}
                    <button
                      className="btn"
                      type="button"
                      onClick={e => submitButton(e)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          float: 'right',
                          display: 'inline-block',
                          fontSize: '1.2rem',
                          color: '#FFFFFF'
                        }}
                      />
                    </button>
                  </h2>
                  <p>{project.content}</p>
                </div>
                <div className="card-action" style={{ padding: '5px 10px' }}>
                  <span style={{ float: 'left' }}>
                    <span style={{ color: '#DCDCDC' }}>
                      {' '}
                      <I18n t="postedBy1" />
                    </span>{' '}
                    <I18n t="postedBy2" />
                  </span>
                  <span style={{ float: 'right' }}>
                    <Moment format="DD / MM / YYYY">
                      {project.createdAt.toDate()}
                    </Moment>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const projects = state.firestore.data.projects;
  const project = projects ? projects[id] : null;
  return {
    project: project,
    id: id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchDeleteProject: (e, id) => {
      e.preventDefault();
      dispatch(deleteProject(id));
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'projects' }])
)(NewsDetails);
