import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload as squareIcon } from '@fortawesome/free-solid-svg-icons';
import I18n from '../../i18n';

const AddEmployeeButton = () => {
  return (
    <div className="col col-md-12">
      <button
        type="button"
        className="btn btn-round btn-pink"
        data-toggle="modal"
        data-target="#add-employee-modal"
        style={{ minWidth: '200px' }}
      >
        <I18n t="add_employee" />
        <FontAwesomeIcon
          icon={squareIcon}
          style={{ fontSize: '18px', marginLeft: '10px' }}
        />
      </button>
    </div>
  );
};

export default AddEmployeeButton;
