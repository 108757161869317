import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { db } from '../../config/firebaseConfig.js';

const AddCertEnModal = ({ cert }) => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const docRef = db.collection('certificates')
      .doc();

    const newCert = {
      ...cert.mkCert,
      en: {
        url: cert.mkCert.url,
        title,
        author,
        column: cert.mkCert.column,
        row: cert.mkCert.row,
        id: docRef.id
      }
    };

    docRef
      .set({ ...newCert })
      .then(certificate => {
        dispatch({
          type: 'SET_CERT_ID',
          payload: certificate.id
        });
      })
      .catch(err => {
        dispatch({ type: 'CREATE_CERTIFICATE_ERROR', payload: err.message });
      });
  };

  return (
    <div className="container">
      <div
        id="add-cert-en-modal"
        className="modal fade"
        role="dialog"
        aria-labelledby="addCertEnModal"
        aria-hidden="true"
        tabIndex="-1"
        style={{ fontFamily: 'Montserrat' }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ minWidth: '620px', height: '100%' }}
        >
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'pink' }}>
              <img
                src={require('../../assets/img/logo.png')}
                height="32"
                width="32"
                alt="logo"
                style={{ marginRight: '20px' }}
              />
              <h5 className="modal-title" id="addCertEnModal">
                Add a Certificate
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body text-center" style={{ height: '600px' }}>
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <h4>English Version</h4>
                  <br/>
                  <div className="form-group">
                    <label
                      htmlFor="titleCert"
                      style={{ fontSize: '1.1rem', fontWeight: '500' }}
                    >
                      Title
                    </label>
                    <input
                      className="form-control"
                      id="titleCert"
                      type="text"
                      name="add-cert-en-title"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="author"
                      style={{ fontSize: '1.1rem', fontWeight: '500' }}
                    >
                      Recipient
                    </label>
                    <input
                      className="form-control"
                      id="titleCert"
                      type="text"
                      name="add-cert-en-author"
                      value={author}
                      onChange={e => setAuthor(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="add-cert-en-column"
                      style={{ fontSize: '1.1rem', fontWeight: '500' }}
                    >
                      Column
                    </label>
                    <select
                      className="form-control"
                      id="add-cert-en-column"
                      type="text"
                      name="add-cert-en-column"
                      value={cert.mkCert.column}
                      disabled
                    >
                      <option>{cert.mkCert.column}</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="add-cert-en-row"
                      style={{ fontSize: '1.1rem', fontWeight: '500' }}
                    >
                      Row
                    </label>
                    <select
                      className="form-control"
                      id="add-cert-en-row"
                      type="text"
                      name="add-cert-en-row"
                      value={cert.mkCert.row}
                      disabled
                    >
                      <option>{cert.mkCert.row}</option>
                    </select>
                  </div>

                  <br/>
                  <br/>
                  <br/>
                  <div
                    className="modal-footer"
                    style={{ backgroundColor: 'pink' }}
                  >
                    <button
                      type="button"
                      className="btn btn-warning"
                      data-dismiss="modal"
                      style={{ color: 'white' }}
                    >
                      Back
                    </button>
                    <button type="submit" className="btn btn-pink">
                      Post
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  cert: state.cert
});

export default connect(mapStateToProps)(AddCertEnModal);
