import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { SET_CURRENT_EMP } from '../store/actions/types.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faEnvelope,
  faPhone,
  faUserMinus,
  faUserEdit
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import I18n from '../../i18n';

const OurTeamCard = ({ emp: employee, auth }) => {
  const dispatch = useDispatch();

  const setCurrent = employee => {
    dispatch({
      type: SET_CURRENT_EMP,
      payload: employee
    });
  };

  const partnered = employee.partner === true ? <I18n t="partner"/> : null;

  const showSecondEmail =
    employee.emailTwo && employee.emailTwo.length > 1 ? (
      <Fragment>
        <FontAwesomeIcon icon={faEnvelope}/>{' '}
        <a href={`mailto:${employee.emailTwo}`}>{employee.emailTwo}</a>
        <br/>
      </Fragment>
    ) : null;

  const showLinkedin =
    employee.linkedin.length > 1 ? (
      <Fragment>
        <FontAwesomeIcon icon={faLinkedin}/>{' '}
        <a href={employee.linkedin} target="_blank" rel="noopener noreferrer">
          {employee.linkedin}
        </a>
        <br/>
      </Fragment>
    ) : null;

  const showSecondCV =
    employee.cvLink2.length > 1 ? (
      <a
        href={employee.cvLink2}
        className="btn our-team-a"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '20px' }}/>
        &nbsp;CV en
      </a>
    ) : null;

  const deleteButton = auth.uid ? (
    <button
      type="button"
      className="btn btn-danger"
      data-toggle="modal"
      data-target="#remove-employee-modal"
      onClick={() => setCurrent(employee)}
      style={{ marginLeft: '30px' }}
    >
      <FontAwesomeIcon
        icon={faUserMinus}
        data-toggle="tooltip"
        data-placement="top"
        title="Remove Employee"
      />
    </button>
  ) : null;

  const editButton = auth.uid ? (
    <button
      type="button"
      className="btn btn-danger"
      data-toggle="modal"
      data-target="#update-employee-modal"
      onClick={() => setCurrent(employee)}
      style={{ marginLeft: '30px' }}
    >
      <FontAwesomeIcon
        icon={faUserEdit}
        data-toggle="tooltip"
        data-placement="top"
        title="Update Employee"
      />
    </button>
  ) : null;

  return (
    <div className="col col-lg-6 col-md-6">
      <div className="team-player">
        <img
          src={employee.imageUrl}
          alt="Team Profile"
          className="rounded-circle img-fluid img-raised"
          style={style}
        />

        <div className={employee.partner ? 'partner-align' : ''}>
          <h4 className="title">{employee.firstName.concat(` ${employee.lastName}`)}</h4>
          <p className="our-team-category">{partnered}</p>
          <p className="our-team-p">{employee.subtext}</p>
          <br/>
        </div>
        <div>
          <p className="our-team-g text-center">
            <FontAwesomeIcon icon={faPhone}/> {employee.phone}
            <br/>
            <FontAwesomeIcon icon={faEnvelope}/>{' '}
            <a href={`mailto:${employee.emailOne}`}>{employee.emailOne}</a>
            <br/>
            {showSecondEmail}
            {showLinkedin}
          </p>
        </div>
        <a
          href={employee.cvLink}
          className="btn our-team-a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '20px' }}/>
          &nbsp;CV
        </a>
        {showSecondCV}
        {editButton}
        {deleteButton}
      </div>
    </div>
  );
};

const style = {
  maxWidth: '280px'
};

export default OurTeamCard;
