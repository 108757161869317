import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import AddPostButton from '../buttons/AddPostButton.js';
import NewsList from './NewsList.js';
import I18n from '../../i18n';

class News extends Component {
  render() {
    const { auth, projects } = this.props;
    const addPostButton = auth.uid ? <AddPostButton /> : null;

    return (
      <div id="6" className="section section-news">
        <div id="news-scroll" className="container">
          <div className="text-center">
            <h1 className="h1-seo">
              <I18n t="news" />
            </h1>
          </div>
          <div className="row justify-content-between">
            {addPostButton}
            <div className="col-12">
              <NewsList projects={projects} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'projects' }])
)(News);
