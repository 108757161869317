import React from 'react';
import { Link } from 'react-router-dom';
import NewsSummary from './NewsSummary.js';
import Loader from './Loader.js';

const NewsList = ({ projects }) => {
  if (!projects) {
    return <Loader />;
  }
  return (
    <div>
      {projects &&
        projects.map(project => {
          return (
            <div
              className="col-6"
              style={{ display: 'inline-block' }}
              key={project.id}
            >
              <Link
                to={'/project/' + project.id}
                style={{ textDecoration: 'none' }}
              >
                <NewsSummary project={project} />
              </Link>
            </div>
          );
        })}
    </div>
  );
};

export default NewsList;
