import React from 'react';
import { withRouter } from 'react-router-dom';
import I18n from '../../i18n';

import image1 from '../../assets/img/office/office-15-rework.jpg';
import image2 from '../../assets/img/office/office-14-rework.jpg';
import image3 from '../../assets/img/office/office-13-rework.jpg';

const About = ({ location }) => {
  const bgImgStyle = {
    backgroundImage: `url(${image1})`,
    marginTop: '520px',
    marginLeft: '-105px'
  };
  const bgImgStyle2 = {
    backgroundImage: `url(${image2})`
  };
  const bgImgStyle3 = {
    backgroundImage: `url(${image3})`
  };

  return (
    <div id="1" className="section section-about-us">
      <div id="about-us-scroll" className="container text-center">
        <div className="container justify-content-md-center">
          <div className="col col-lg-12 col-md-12">
            <h1 className="h1-seo">
              <I18n t="About us title" />
            </h1>
            <h5 className="about-us-description">
              <I18n t="first para" />
              <br></br>
              <br></br>
              <I18n t="second para" />
            </h5>
          </div>
          <div className="separator separator-primary"></div>
          <div className="section section-story-overview">
            <div className="row">
              <div className="col col-sm-6">
                <div
                  className="image-container image-left d-none d-sm-block"
                  style={bgImgStyle2}
                ></div>
                <p className="blockquote blockquote-pink d-none d-sm-block">
                  <I18n t="blockquote" />
                </p>
                <div
                  className="image-container d-none d-sm-block"
                  style={bgImgStyle}
                ></div>
              </div>
              <div className="col col-sm-5">
                <div
                  className="image-container image-right third-image"
                  style={bgImgStyle3}
                ></div>
                <h2>
                  <I18n t="professionals" />
                </h2>

                <br></br>
                <h5 className="about-us-description">
                  <I18n t="para3" />
                </h5>
                <br></br>
                <h5 className="about-us-description">
                  <I18n t="para4" />
                </h5>
                <br></br>
                <h5 className="about-us-description">
                  <I18n t="para5" />
                </h5>
                <br></br>
                <ul className="about-us-ul">
                  <li style={{ marginBottom: '5px' }}>
                    <I18n t="para6" />
                  </li>
                  <li>
                    <I18n t="para7" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(About);
