import { SET_CERTIFICATES } from '../actions/types.js';

const initState = {
  certs: [],
  mkCert: {},
  error: null
};

const certReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CERTIFICATES:
      return {
        ...state,
        certs: action.payload,
        error: null
      };
    case 'MK_CERT':
      return {
        ...state,
        mkCert: { ...action.payload }
      };
    case 'CREATE_CERTIFICATE_ERROR':
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default certReducer;
