import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const CertificatesList = ({ cert, auth, onDelete }) => {
  const deleteButton = auth.uid ? (
    <a href="#!" onClick={() => onDelete(cert)}>
      <FontAwesomeIcon
        icon={faTrashAlt}
        color="white"
        style={{ float: 'right', margin: '10px 10px' }}
      />
    </a>
  ) : null;

  return (
    <div className="cert-div">
      {deleteButton}
      <a
        href={cert.url}
        className="btn btn-primary"
        target="_blank"
        rel="noopener noreferrer"
        type="button"
      >
        {cert.title}
      </a>

      <div className="cert-small">
        <p>{cert.author}</p>
      </div>
    </div>
  );
};

export default CertificatesList;
