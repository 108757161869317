import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectEmployee } from '../store/selectors';
import { MK_EMP } from '../store/actions/types.js';
import { firebase } from '../../config/firebaseConfig.js';

const UpdateEmployeeModal = () => {
  const dispatch = useDispatch();
  const currentEmployee = useSelector(selectEmployee);
  const [employee, setEmployee] = useState(null);

  const [imageFile, setImageFile] = useState(null);
  const [cvMkFile, setCvMkFile] = useState(null);
  const [cvEnFile, setCvEnFile] = useState(null);

  const [progress, setProgress] = useState('');
  const [progress1, setProgress1] = useState('');
  const [progress2, setProgress2] = useState('');

  useEffect(() => {
    if (currentEmployee) {
      setEmployee(currentEmployee);
    }
  }, [currentEmployee]);

  const handleFieldChange = e => {
    if (!e.target) {
      setEmployee(pre => ({
        ...pre,
        ...e
      }));
      return;
    }

    const { name, value } = e.target;

    setEmployee(pre => ({
      ...pre,
      [name]: value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    let timestamp = firebase.firestore.Timestamp.fromDate(new Date());

    const newEmployee = {
      ...employee,
      createdAt: timestamp
    };

    dispatch({
      type: MK_EMP,
      payload: newEmployee
    });
  };

  const handleImageChange = e => {
    if (e.target.files[0]) {
      const doc = e.target.files[0];
      setImageFile(() => doc);
    }
  };

  const handleChangeCvMk = e => {
    if (e.target.files[0]) {
      const doc = e.target.files[0];
      setCvMkFile(() => doc);
    }
  };

  const handleChangeCvEn = e => {
    if (e.target.files[0]) {
      const doc = e.target.files[0];
      setCvEnFile(() => doc);
    }
  };

  const handleImageUpload = () => {
    const uploadTask = firebase
      .storage()
      .ref(`employee/${imageFile.name}`)
      .put(imageFile);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const data = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress2(data);
      },
      error => {
        console.log(error);
      },
      () => {
        firebase
          .storage()
          .ref('employee')
          .child(imageFile.name)
          .getDownloadURL()
          .then(url => {
            handleFieldChange({ imageUrl: url });
          });
      }
    );
  };

  const handleUploadCvMk = () => {
    const uploadTask = firebase
      .storage()
      .ref(`cv/${cvMkFile.name}`)
      .put(cvMkFile);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const data = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(data);
      },
      error => {
        console.log(error);
      },
      () => {
        firebase
          .storage()
          .ref('cv')
          .child(cvMkFile.name)
          .getDownloadURL()
          .then(url => {
            handleFieldChange({ cvLink1: url });
          });
      }
    );
  };

  const handleUploadCvEn = () => {
    const uploadTask = firebase
      .storage()
      .ref(`cv/${cvEnFile.name}`)
      .put(cvEnFile);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const data = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress1(data);
      },
      error => {
        console.log(error);
      },
      () => {
        firebase
          .storage()
          .ref('cv')
          .child(cvEnFile.name)
          .getDownloadURL()
          .then(url => {
            handleFieldChange({ cvLink2: url });
          });
      }
    );
  };

  return (
    <div className="container">
      <div
        id="update-employee-modal"
        className="modal fade"
        role="dialog"
        aria-labelledby="update-employee-modal"
        aria-hidden="true"
        tabIndex="-1"
        style={{ fontFamily: 'Montserrat' }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ minWidth: '900px' }}
        >
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'pink' }}>
              <img
                src={require('../../assets/img/logo.png')}
                height="32"
                width="32"
                alt="logo"
                style={{ marginRight: '20px' }}
              />
              <h5 className="modal-title" id="updateEmployeeModal">
                Измени Вработен
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <div className="row">
                    {/* First Name */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="firstName"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Име
                        </label>
                        <input
                          name="firstName"
                          className="form-control"
                          id="firstName"
                          type="text"
                          value={employee?.firstName || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Last Name */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="lastName"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Презиме
                        </label>
                        <input
                          name="lastName"
                          className="form-control"
                          id="lastName"
                          type="text"
                          value={employee?.lastName || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Subtext */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="subtext"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Текст (Овластен за..)
                        </label>
                        <input
                          name="subtext"
                          className="form-control"
                          id="subtext"
                          type="text"
                          value={employee?.subtext || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Phone */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="phone"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Телефон
                        </label>
                        <input
                          name="phone"
                          className="form-control"
                          id="phone"
                          type="text"
                          value={employee?.phone || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Email 1 */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="emailOne"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Email 1
                        </label>
                        <input
                          name="emailOne"
                          className="form-control"
                          id="emailOne"
                          type="text"
                          value={employee?.emailOne || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Email 2 */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="emailTwo"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Email 2 (Опционално)
                        </label>
                        <input
                          name="emailTwo"
                          className="form-control"
                          id="emailTwo"
                          type="text"
                          value={employee?.emailTwo || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Linkedin */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="linkedin"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Linkedin
                        </label>
                        <input
                          name="linkedin"
                          className="form-control"
                          id="linkedin"
                          type="text"
                          value={employee?.linkedin || ""}
                          onChange={handleFieldChange}
                        />
                      </div>
                    </div>

                    {/* Partner */}
                    <div className="col-4 mt-5 ml-2">
                      <div className="input-group">
                        <div className="form-group form-check">
                          <input
                            name="partner"
                            type="checkbox"
                            className="form-check-input"
                            id="partnerCheck"
                            value="true"
                            onChange={handleFieldChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="partnerCheck"
                          >
                            Партнер на Идеја Плус
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* CV 1 */}
                    <div className="col-12">
                      <div className="input-group" style={{ margin: '0' }}>
                        <div className="custom-file">
                          <input
                            name="cvLink1"
                            type="file"
                            className="custom-file-input"
                            id="cvLink1"
                            onChange={handleChangeCvMk}
                          />
                          <label className="custom-file-label" htmlFor="cvLink1">
                            {employee?.cvLink ? "Корисникот има македонско CV.." : "Одберете Македонско CV.."}
                          </label>
                        </div>
                        <div
                          className="input-group-append"
                          style={{ marginTop: '-10px' }}
                        >
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleUploadCvMk}
                          >
                            Прикачи
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* CV1 Progress */}
                    <div className="col-12 mb-3">
                      <div className="progress" style={{ height: '20px' }}>
                        <div
                          className="progress-bar-striped bg-pink text-center"
                          role="progressbar"
                          style={{ width: progress && '100%', paddingTop: '10px' }}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress > 0 ? `${progress}% прикачено` : null}
                        </div>
                      </div>
                    </div>

                    {/* CV 2 */}
                    <div className="col-12">
                      <div className="input-group" style={{ margin: '0' }}>
                        <div className="custom-file">
                          <input
                            name="cvLink2"
                            type="file"
                            className="custom-file-input"
                            id="cvLink2"
                            onChange={handleChangeCvEn}
                          />
                          <label className="custom-file-label" htmlFor="cvLink2">
                            {employee?.cvLink2 ? "Корисникот има англиско CV.." : "Одберете Англиско CV.."}
                          </label>
                        </div>
                        <div
                          className="input-group-append"
                          style={{ marginTop: '-10px' }}
                        >
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleUploadCvEn}
                          >
                            Прикачи
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* CV2 Progress */}
                    <div className="col-12 mb-3">
                      <div className="progress" style={{ height: '20px' }}>
                        <div
                          className="progress-bar-striped bg-pink text-center"
                          role="progressbar"
                          style={{ width: progress1 && '100%', paddingTop: '10px' }}
                          aria-valuenow={progress1}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress1 > 0 ? `${progress1}% прикачено` : null}
                        </div>
                      </div>
                    </div>

                    {/* Upload Image */}
                    <div className="col-12">
                      <div className="input-group" style={{ margin: '0' }}>
                        <div className="custom-file">
                          <input
                            name="imageUrl"
                            type="file"
                            className="custom-file-input"
                            id="imageUrl"
                            onChange={handleImageChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="imageUrl"
                          >
                            {employee?.imageUrl ? "Кликнете за да ја смените сликата.." : "Корисникот има слика.."}
                          </label>
                        </div>
                        <div
                          className="input-group-append"
                          style={{ marginTop: '-10px' }}
                        >
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleImageUpload}
                          >
                            Прикачи
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Image Progress */}
                    <div className="col-12 mb-3">
                      <div className="progress" style={{ height: '20px' }}>
                        <div
                          className="progress-bar-striped bg-pink text-center"
                          role="progressbar"
                          style={{ width: progress2 && '100%', paddingTop: '10px' }}
                          aria-valuenow={progress2}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress2 > 0 ? `${progress2}% прикачено` : ''}
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary btn-md btn-block"
                    >
                      Потврди
                    </button>

                    <div
                      className="modal-footer col-12"
                      style={{ backgroundColor: 'pink' }}
                    >
                      <button
                        type="button"
                        className="btn btn-pink"
                        data-toggle="modal"
                        data-target="#update-employee-en-modal"
                      >
                        Продолжи (Англиски)
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEmployeeModal;
