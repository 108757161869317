import { createStore, applyMiddleware, compose } from 'redux';
import { getFirebase } from 'react-redux-firebase';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer.js';

export default function configureStore(initalState) {
  const middlewares = [thunk.withExtraArgument(getFirebase)];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  return createStore(
    rootReducer,
    initalState,
    composeEnhancers(composedEnhancers)
  );
}
