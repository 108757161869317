import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../store/actions/authActions.js';
import { withRouter } from 'react-router-dom';

class SignIn extends Component {
  state = {
    email: '',
    password: ''
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.signIn(this.state);
    this.props.history.push('/');
  };

  render() {
    const { authError } = this.props;

    return (
      <div className="container" style={{ marginTop: '150px' }}>
        <h1 className="h1-seo text-center">Login</h1>
        <div className="section section-sign-in">
          <div className="row justify-content-center align-items-center">
            <div className="col-8">
              <form className="sign-in-form" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    onChange={this.handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-login"
                  style={{
                    backgroundColor: '#f76e9b',
                    borderColor: '#f76e9b',
                    minWidth: '100px'
                  }}
                >
                  Login
                </button>
                <div className="red-text center">
                  {authError ? <p>{authError}</p> : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
