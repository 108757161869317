import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const TransparencyList = ({ trans, auth, onDelete }) => {
  const deleteButton = auth.uid ? (
    <a href="#!" onClick={() => onDelete(trans)}>
      <FontAwesomeIcon icon={faTrashAlt} color="white" />
    </a>
  ) : null;

  return (
    <div className="cert-div">
      <a
        className="btn btn-primary"
        href={trans.url}
        target="_blank"
        rel="noopener noreferrer"
        type="button"
      >
        {trans.title}
      </a>
      {deleteButton}
    </div>
  );
};

export default TransparencyList;
