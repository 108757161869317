import React from 'react';
import I18n from '../i18n';

const Footer = () => {
  return (
    <footer className="footer" data-background-color="black">
      <div className="container">
        <a href="#!" style={{ color: 'white', fontSize: '1.1rem' }}>
          <I18n t="ideja revizija konsalting" />
        </a>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, <I18n t="design and code" />{' '}
          <a
            href="https://facebook.com/kartalov"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white' }}
          >
            <I18n t="kartalov petar" />
          </a>
          .
        </div>
      </div>
    </footer>
  );
};

export default Footer;
