import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MK_EMP } from '../store/actions/types.js';
import { firebase } from '../../config/firebaseConfig.js';

const AddEmployeeModal = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [partner, setPartner] = useState(false);
  const [subtext, setSubtext] = useState('');
  const [phone, setPhone] = useState('');
  const [emailOne, setEmailOne] = useState('');
  const [emailTwo, setEmailTwo] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [cvLink, setCvLink] = useState('');
  const [cvLink2, setCvLink2] = useState('');
  const [file, setFile] = useState(null);
  const [cvFile1, setCvFile1] = useState(null);
  const [cvFile2, setCvFile2] = useState(null);
  const [progress, setProgress] = useState('');
  const [progress1, setProgress1] = useState('');
  const [progress2, setProgress2] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    let timestamp = firebase.firestore.Timestamp.fromDate(new Date());

    const newEmployee = {
      firstName,
      lastName,
      partner,
      subtext,
      phone,
      emailOne,
      emailTwo,
      linkedin,
      imageUrl,
      cvLink,
      cvLink2,
      createdAt: timestamp
    };

    dispatch({
      type: MK_EMP,
      payload: newEmployee
    });
  };

  const handleChange = e => {
    if (e.target.files[0]) {
      const doc = e.target.files[0];
      setFile(() => doc);
    }
  };

  const handleChangeCv1 = e => {
    if (e.target.files[0]) {
      const doc = e.target.files[0];
      setCvFile1(() => doc);
    }
  };

  const handleChangeCv2 = e => {
    if (e.target.files[0]) {
      const doc = e.target.files[0];
      setCvFile2(() => doc);
    }
  };

  const handleUpload = () => {
    const doc = file;
    const uploadTask = firebase
      .storage()
      .ref(`employee/${doc.name}`)
      .put(doc);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const data = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress2(data);
      },
      error => {
        console.log(error);
      },
      () => {
        firebase
          .storage()
          .ref('employee')
          .child(doc.name)
          .getDownloadURL()
          .then(url => {
            setImageUrl(url);
          });
      }
    );
  };

  const handleUploadCv1 = () => {
    const doc = cvFile1;
    const uploadTask = firebase
      .storage()
      .ref(`cv/${doc.name}`)
      .put(doc);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const data = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(data);
      },
      error => {
        console.log(error);
      },
      () => {
        firebase
          .storage()
          .ref('cv')
          .child(doc.name)
          .getDownloadURL()
          .then(url => {
            setCvLink(url);
          });
      }
    );
  };

  const handleUploadCv2 = () => {
    const doc = cvFile2;
    const uploadTask = firebase
      .storage()
      .ref(`cv/${doc.name}`)
      .put(doc);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const data = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress1(data);
      },
      error => {
        console.log(error);
      },
      () => {
        firebase
          .storage()
          .ref('cv')
          .child(doc.name)
          .getDownloadURL()
          .then(url => {
            setCvLink2(url);
          });
      }
    );
  };

  return (
    <div className="container">
      <div
        id="add-employee-modal"
        className="modal fade"
        role="dialog"
        aria-labelledby="addEmployeeModal"
        aria-hidden="true"
        tabIndex="-1"
        style={{ fontFamily: 'Montserrat' }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ minWidth: '900px' }}
        >
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'pink' }}>
              <img
                src={require('../../assets/img/logo.png')}
                height="32"
                width="32"
                alt="logo"
                style={{ marginRight: '20px' }}
              />
              <h5 className="modal-title" id="addEmployeeModal">
                Додади Вработен
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <div className="row">
                    {/* First Name */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="firstName"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Име
                        </label>
                        <input
                          className="form-control"
                          id="firstName"
                          type="text"
                          name="add-employee-firstName"
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Last Name */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="lastName"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Презиме
                        </label>
                        <input
                          className="form-control"
                          id="lastName"
                          type="text"
                          name="add-employee-lastName"
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Subtext */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="subtext"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Текст (Овластен за..)
                        </label>
                        <input
                          className="form-control"
                          id="subtext"
                          type="text"
                          name="add-employee-subtext"
                          value={subtext}
                          onChange={e => setSubtext(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Phone */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="phone"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Телефон
                        </label>
                        <input
                          className="form-control"
                          id="phone"
                          type="text"
                          name="add-employee-phone"
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Email 1 */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="emailOne"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Email 1
                        </label>
                        <input
                          className="form-control"
                          id="emailOne"
                          type="text"
                          name="add-employee-emailOne"
                          value={emailOne}
                          onChange={e => setEmailOne(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Email 2 */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="emailTwo"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Email 2 (Опционално)
                        </label>
                        <input
                          className="form-control"
                          id="emailTwo"
                          type="text"
                          name="add-employee-emailTwo"
                          value={emailTwo}
                          onChange={e => setEmailTwo(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Linkedin */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="linkedin"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Linkedin
                        </label>
                        <input
                          className="form-control"
                          id="linkedin"
                          type="text"
                          name="add-employee-linkedin"
                          value={linkedin}
                          onChange={e => setLinkedin(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Partner */}
                    <div className="col-4 mt-5 ml-2">
                      <div className="input-group">
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="partnerCheck"
                            value="true"
                            onChange={e => setPartner(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="partnerCheck"
                          >
                            Партнер на Идеја Плус
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* CV 1 */}
                    <div className="col-12">
                      <div className="input-group" style={{ margin: '0' }}>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="addCv1"
                            onChange={handleChangeCv1}
                          />
                          <label className="custom-file-label" htmlFor="addCv1">
                            Одберете Македонско CV..
                          </label>
                        </div>
                        <div
                          className="input-group-append"
                          style={{ marginTop: '-10px' }}
                        >
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleUploadCv1}
                          >
                            Прикачи
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* CV1 Progress */}
                    <div className="col-12 mb-3">
                      <div className="progress" style={{ height: '20px' }}>
                        <div
                          className="progress-bar-striped bg-pink text-center"
                          role="progressbar"
                          style={{ width: progress && '100%', paddingTop: '10px' }}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress > 0 ? `${progress}% прикачено` : null}
                        </div>
                      </div>
                    </div>

                    {/* CV 2 */}
                    <div className="col-12">
                      <div className="input-group" style={{ margin: '0' }}>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="addCv2"
                            onChange={handleChangeCv2}
                          />
                          <label className="custom-file-label" htmlFor="addCv2">
                            Одберете Англиско CV..
                          </label>
                        </div>
                        <div
                          className="input-group-append"
                          style={{ marginTop: '-10px' }}
                        >
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleUploadCv2}
                          >
                            Прикачи
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* CV2 Progress */}
                    <div className="col-12 mb-3">
                      <div className="progress" style={{ height: '20px' }}>
                        <div
                          className="progress-bar-striped bg-pink text-center"
                          role="progressbar"
                          style={{ width: progress1 && '100%', paddingTop: '10px' }}
                          aria-valuenow={progress1}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress1 > 0 ? `${progress1}% прикачено` : null}
                        </div>
                      </div>
                    </div>

                    {/* Upload Image */}
                    <div className="col-12">
                      <div className="input-group" style={{ margin: '0' }}>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="addNewEmployee"
                            onChange={handleChange}
                            required
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="addNewEmployee"
                          >
                            Одберете слика..
                          </label>
                        </div>
                        <div
                          className="input-group-append"
                          style={{ marginTop: '-10px' }}
                        >
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleUpload}
                          >
                            Прикачи
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Image Progress */}
                    <div className="col-12 mb-3">
                      <div className="progress" style={{ height: '20px' }}>
                        <div
                          className="progress-bar-striped bg-pink text-center"
                          role="progressbar"
                          style={{ width: progress2 && '100%', paddingTop: '10px' }}
                          aria-valuenow={progress2}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress2 > 0 ? `${progress2}% прикачено` : null}
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary btn-md btn-block"
                    >
                      Потврди
                    </button>

                    <div
                      className="modal-footer col-12"
                      style={{ backgroundColor: 'pink' }}
                    >
                      <button
                        type="button"
                        className="btn btn-pink"
                        data-toggle="modal"
                        data-target="#add-employee-en-modal"
                      >
                        Продолжи (Англиски)
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployeeModal;
