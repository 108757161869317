export const GET_NEWS = 'GET_NEWS';
export const ADD_NEWS = 'ADD_NEWS';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';
export const NEWS_ERROR = 'NEWS_ERROR';
export const SET_CURRENT = 'SET_CURRENT';

export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';

export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const LOADING_USER = 'LOADING_USER';
export const SET_USER = 'SET_USER';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';

export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_EMPLOYEES_ERR = 'SET_EMPLOYEES_ERR';
export const MK_EMP = 'MK_EMP';
export const SET_EMP_ID = 'SET_EMP_ID';
export const SET_CURRENT_EMP = 'SET_CURRENT_EMP';
export const CLEAR_CURRENT_EMP = 'CLEAR_CURRENT_EMP';
export const DELETE_EMP = 'DELETE_EMP';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const CREATE_EMPLOYEE_ERROR = 'CREATE_EMPLOYEE_ERROR';

export const SET_CERTIFICATES = 'SET_CERTIFICATES';
export const SET_CERTIFICATES_ERR = 'SET_CERTIFICATES_ERR';

export const MK_TRANS = 'MK_TRANS';
