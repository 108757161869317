import firebase from 'firebase/app'; // core features of firebase app
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

export const firebaseConfig = {
  apiKey: 'AIzaSyBh2diHmXz45DLPzLje1F98pAp2WcJVgAE',
  authDomain: 'ideja-plus.firebaseapp.com',
  databaseURL: 'https://ideja-plus.firebaseio.com',
  projectId: 'ideja-plus',
  storageBucket: 'ideja-plus.appspot.com',
  messagingSenderId: '435148178319',
  appId: '1:435148178319:web:6facf99868a8cf3d9ec1fa',
  measurementId: 'G-TYBWN1HR29'
};

firebase.initializeApp(firebaseConfig);
firebase.storage();

const db = firebase.firestore();
const auth = firebase.auth();

export { firebase, db, auth };
