import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp as icon } from '@fortawesome/free-solid-svg-icons';

class ScrollButton extends Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
      <button
        className="btn scroll-button"
        style={{ backgroundColor: '#f76e9c', color: '#ffffff' }}
        onClick={() => {
          this.scrollToTop();
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  }
}

export default ScrollButton;
