import {
  SET_EMPLOYEES,
  SET_EMPLOYEES_ERR,
  SET_CURRENT_EMP,
  CLEAR_CURRENT_EMP,
  CREATE_EMPLOYEE_ERROR,
  MK_EMP,
  SET_EMP_ID
} from '../actions/types.js';

const initState = {
  emps: [],
  mkEmp: {},
  currentEmpId: '',
  current: null,
  error: null
};

const empReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_EMPLOYEES:
      return {
        ...state,
        emps: action.payload,
        error: null
      };
    case SET_EMPLOYEES_ERR:
      return {
        ...state,
        error: action.payload
      };
    case MK_EMP:
      return {
        ...state,
        mkEmp: { ...action.payload }
      };
    case SET_EMP_ID:
      return {
        ...state,
        currentEmpId: action.payload
      };
    case SET_CURRENT_EMP:
      return {
        ...state,
        current: action.payload
      };
    case CLEAR_CURRENT_EMP:
      return {
        ...state,
        current: null
      };
    case CREATE_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default empReducer;
