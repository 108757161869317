import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { db } from '../../config/firebaseConfig.js';

const AddTransModal = ({ trans }) => {
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const docRef = db.collection('transparency')
      .doc();

    const newTrans = {
      ...trans.mkTrans,
      en: {
        url: trans.mkTrans.url,
        title,
        createdAt: trans.mkTrans.createdAt,
        id: docRef.id
      }
    };

    docRef
      .set({ ...newTrans })
      .then(trans => {
        dispatch({
          type: 'SET_TRANS_ID',
          payload: trans.id
        });
      })
      .catch(err => {
        dispatch({ type: 'CREATE_TRANS_ERROR', payload: err.message });
      });
  };

  return (
    <div className="container">
      <div
        id="add-trans-en-modal"
        className="modal fade"
        role="dialog"
        aria-labelledby="addTransEnModal"
        aria-hidden="true"
        tabIndex="-1"
        style={{ fontFamily: 'Montserrat' }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ minWidth: '550px' }}
        >
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'pink' }}>
              <img
                src={require('../../assets/img/logo.png')}
                height="32"
                width="32"
                alt="logo"
                style={{ marginRight: '20px' }}
              />
              <h5 className="modal-title" id="addTransEnModal">
                Add a Report
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <h4>English Version</h4>
                  <br/>
                  <div className="form-group">
                    <label
                      htmlFor="titleTRANS"
                      style={{ fontSize: '1.1rem', fontWeight: '500' }}
                    >
                      Title
                    </label>
                    <input
                      className="form-control"
                      id="titleTRANS"
                      type="text"
                      name="add-trans-en-title"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </div>

                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div
                    className="modal-footer"
                    style={{ backgroundColor: 'pink' }}
                  >
                    <button
                      type="button"
                      className="btn btn-warning"
                      data-dismiss="modal"
                      style={{ color: 'white' }}
                    >
                      Back
                    </button>
                    <button type="submit" className="btn btn-pink">
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  trans: state.transparency
});

export default connect(mapStateToProps)(AddTransModal);
