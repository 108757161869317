import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import I18n from '../i18n';

const Download = () => {
  return (
    <div
      id="8"
      className="section section-download"
      data-background-color="black"
    >
      <div id="download-section" className="container">
        <div className="justify-content-md-center sharing-area text-center row">
          <div className="col-md-12 col-lg-8 text-center">
            <h3>
              <I18n t="smf" />
            </h3>
          </div>
          <div className="icon-area col-md-12 col-lg-8 text-center">
            <a
              className="btn-footer btn-icon btn btn-twitter btn-lg text-center"
              href="https://www.facebook.com/idejaplusrevizija"
              id="tooltip86114138"
              size="lg"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: '15px' }}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              className="btn-footer btn-icon btn btn-facebook btn-lg"
              color="facebook"
              href="https://www.linkedin.com/company/idejaplusrevizija/about/"
              id="tooltip735272548"
              size="lg"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '15px' }}
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
