import React from "react";
import { connect } from "react-redux";
import { signOut } from "./store/actions/authActions.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const SignedInLinks = (props) => {
  return (
    <li className="nav-item">
      <a
        href="#!"
        className="nav-link text-white"
        onClick={props.signOut}
        data-toggle="tooltip"
        data-placement="bottom"
        title="Logout"
      >
        <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "1.1rem" }} />
      </a>
    </li>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(SignedInLinks);
