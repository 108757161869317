import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload as squareIcon } from '@fortawesome/free-solid-svg-icons';
import I18n from '../../i18n';

const AddCertButton = () => {
  return (
    <div className="col col-md-12" style={{ marginLeft: '20px' }}>
      <button
        type="button"
        className="btn btn-round btn-pink"
        data-toggle="modal"
        data-target="#add-cert-modal"
        style={{ minWidth: '200px', float: 'left' }}
      >
        <I18n t="add_cert" />
        <FontAwesomeIcon
          icon={squareIcon}
          style={{ fontSize: '18px', marginLeft: '10px' }}
        />
      </button>
    </div>
  );
};

export default AddCertButton;
