import { createI18n } from 'react-router-i18n';

const locales = ['mk', 'en'];

const resources = {
  en: {
    lang1: 'en',
    lang2: 'mk',
    contact: 'Contact',
    news: 'News',
    trans8: 'Transparency report 2010',
    trans7: 'Transparency report 2011',
    trans6: 'Transparency report 2012',
    trans5: 'Transparency report 2013',
    trans4: 'Transparency report 2014',
    trans3: 'Transparency report 2015',
    trans2: 'Transparency report 2016',
    trans1: 'Transparency report 2017',
    trans: 'Transparency report 2018',
    icm: 'International Capital Markets',
    qual: 'Qualifications',
    test7: 'Testimonial for CEFE Trainer for Trainers',
    test6: 'Testimonial for Expertise',
    test5: 'Testimonial for Authorized Accountant - Riste',
    test4: 'Testimonial for Authorized Accountant - Biljana',
    test3: 'Testimonial for Authorized Accountant - Dragi',
    test2: 'Testimonial for Authorized Accountant - Janka',
    test1: 'Testimonial for Investment Advisor - Janka',
    testimonials: 'Testimonials',
    acc2: 'Accreditation for Councelor for Entrepreneurship - Specialist',
    acc1: 'Accreditation for Councelor for Entrepreneurship - Generalist',
    accreditations: 'Accreditations',
    lice4: 'Work License for an Accounting Firm',
    lice3: 'License for Authorized Auditor',
    lice2: 'License for Audit Company',
    lice1: 'License for Expertise',
    lice: 'Licenses',
    cert4: 'Certificate for Support of Small and Middle Companies',
    cert3: 'Certificate for Ethics and Professional Skills',
    cert2: 'Certificates',
    'diploma smetkovodstvo': 'Diploma for Accounting and Business',
    adab: 'Advanced Diploma in Accounting and Business',
    diplomas: 'Diplomas',
    transparency: 'Transparency',
    services: 'Services',
    certificates: 'Certificates',
    team: 'Team',

    'logo subtitle': 'revizija & konsalting DOO',
    'about ideja plus': 'about ideja plus',
    'About us title': 'About Ideja Plus',
    'first para':
      'IDEJA PLUS REVIZIJA & KONSALTING DOO Radovis has been building an image of a quality and competent consulting firm for many years in the areas of financial - accounting and legal aspects of the business entities.',
    'second para':
      'Eighteen Years IDEJA PLUS REVIZIJA & KONSALTING DOO works with high quality performance, reliability, timeliness and accuracy in providing the services it offers to its clients. The achieved reputation is a result of the professional team engaged. Our success is confirmed by the constant increase in the number of satisfied customers of our services with domestic and foreign capital. The commitment to continually improve quality remains our responsibility in the future.',
    blockquote:
      'Through regular oral and written communication and lectures we organize for our clients, we provide timely information on all the latest developments in the field of economics and law.',
    professionals:
      'We as a TEAM of professionals are the best solution for Your Business',
    para3:
      ' Continuous employee development is a commitment we take pride in, as only qualified employees contribute to the growth and development of our company and our clients. Ideja Plus’s team has licenses for Certified Auditor, Certified Accountant.',
    para4:
      'IDEJA PLUS REVIZIJA & KONSALTING DOO is a reliable associate in the field of economic and legal consulting. The complete advisory team of our company is able to provide you with efficient audit, accounting, tax, legal and financial services, while fully respecting the Macedonian legislation.',
    para5:
      "Through regular oral and written communication and lectures we organize for our clients, we provide timely information on all the latest developments in the field of economics and law. IDEJA PLUS REVIZIJA & KONSALTING's activity is also focused on educating the management, accounting and financial staff of our clients through:",
    para6:
      'delivering seminars for acquainting the clients with the legislation in the field of finance, accounting, taxation, trade law, labor relations, payment operations and more.',
    para7:
      'delivering specialized seminars and lectures on administration of local taxes and fees, introduction of new payment instruments, application of International Financial Reporting Standards and more.',
    'logo title': 'Ideja Plus',
    'prof dr janka': 'Prof. Dr. Janka Dimitrova',
    phone1: ' 072/208-072',
    email: 'E-mail:',
    'dragi dimitrov': 'Dragi Dimitrov',
    'ilija short': 'Ilija Mitrev',
    cvlink: 'CV',
    'ilija mitrev': 'Prof. Ilija Mitrev',
    'bachelor economics': 'Bachelor of Economics',
    'auditor, appraiser': 'Licensed Auditor, Licensed Appraiser',
    phone2: ' 072/208-074',
    partner: 'Partner of IDEJA PLUS REVIZIJA & KONSALTING',
    author1:
      '  Licensed: Auditor, Accountant, Appraiser, Broker, Investment Counselor, Expert Witness',
    'our team title': 'Our Team',
    'biljana camov': 'Biljana Camov',
    phone3: ' 072/303-312',
    'riste lukarov': 'Riste Lukarov',
    phone4: ' 077/713-478',
    'kamelija trajanova': 'Kamelija Trajanova',
    'assistant auditor only': 'Assistant Auditor',
    phone5: ' 078/316-876',
    'eftimija dimitrova': 'Eftimija Dimitrova',
    acs: 'American College Skopje',
    phone6: ' 072/208-073',
    'gjurgja joseva': 'Gjurgja Joseva',
    'acc audit': 'Licensed Accountant, Assistant Auditor',
    phone7: 'Phone: 070/238-660',
    'adv acc and business': 'Advanced Degree in Accounting and Business',
    'acquired cert': 'Acquired certificates, licenses and testimonials',
    'acert ethics': 'Certificate for Ethics and Professional Skills',
    'dipl acc bus': 'Diploma for Accounting and Business',
    'qual icm': 'Qualifications for International Capital Markets',
    'janka dimitrova': 'Janka Dimitrova',
    'license expertise': 'License for Expertise',
    'license auditing': 'License for an Auditing Firm',
    'ideja revizija konsalting': 'Ideja Plus Revizija & Konsalting DOO',
    'license auditor auth': 'License for Licensed Auditor',
    cert10:
      'Certificate for Consultant for Support of Small and Medium Companies',
    'license company acc': 'License for an Accounting Firm',
    test10: 'Testimonial for Investment Advisor',
    test11: 'Testimonial for Licensed Accountant',
    'accreditation general':
      'Accreditation for Councelor for Entrepreneurship - Generalist',
    'accreditation specialist':
      'Accreditation for Councelor for Entrepreneurship - Specialist',
    serv: 'Services',
    'serv rev': 'Audit',
    'serv rev 1':
      'The audit as a separate discipline that raises the credibility of the information presented in the financial statements is carried out by doing',
    'serv rev 2':
      '• External audit of the financial statements of medium and large entities (Joint Stock Companies, LLC and Ltd.)',
    'serv rev 3':
      '• Internal audit for financial institutions (banks, savings houses, investment funds)',
    'serv rev 4': '• Audit for incorporation, merger and division of companies',
    'serv rev 5':
      '• Audit for increasement the equity capital of the companies',
    'serv rev 6':
      '• Audit for the protection of the minority interests of the companies',
    'serv rev 7': '• Audit for advance dividend payment',
    'serv rev 8': '• Special purpose audits and more.',
    'accounting serv': 'Accounting',
    'acc serv 1':
      'For the needs of many of our clients from a variety of industries (production, services and trade), we are constantly doing:',
    'acc serv 2': '• financial and material records',
    'acc serv 3':
      '• calculation of integrated gross salary and personnel records',
    'acc serv 4': '• Fixed assets records and depreciation calculations;',
    'acc serv 5':
      '• calculation of all types of taxes (VAT, total income tax, income tax and tax deductible expenses, personal income tax)',
    'acc serv 6': '• preparation of PLT',
    'acc serv 7': '• filling out commercial books',
    'comp reg': 'Company Registration',
    'comp reg 6': '• LLC and Ltd',
    'comp reg 5': '• JSC',
    'comp reg 4': '• CTD',
    'comp reg 3': '• Sole proprietor',
    'comp reg 2': '• Associations and foundations',
    'comp reg 1':
      'Applying the provisions of the Company Law, the professional and responsible approach to our clients is expressed through the ability IDEJA PLUS is an authorized registrar for the establishment of:',
    'business plan title': 'Business Plan',
    'inv couns': 'Investment Counseling',
    'app title serv': 'Appraisal',
    'app 1': "We are working on valuing companies' capital for:",
    'app 2': '• sales to whole enterprise',
    'app 3': '• sale or pledge of individual assets or equipment',
    'app 4':
      'Equity capital in the establishment of companies in accordance with the positive legal regulations in the Republic of Macedonia.',
    'business plan text':
      'For the needs of our clients, IDEJA PLUS REVIZIJA & KONSALTING DOO Radovis prepares Business Plan and Investment Programs, in accordance with World Bank Methodology, Macedonian Bank for Development Promotion – MBDP, Agency For Promotion Of Entrepreneurship of The Republic of Macedonia and the IPARD program, which assess the business and viability of a planned investment, in order to meet the requirements of domestic and foreign investors or when seeking co-financing of the planned investment. Having an idea for business expansion is creative and ambitious, but we are checking the feasibility of the idea through a comprehensive and thoroughly designed FEASIBILITY STUDY that will guide the development and success of the company.',
    'expertise serv': 'Expertise',
    'expertise serv 1':
      'For our clients and at the request of the Basic Courts in Republic of Macedonia, we carry out all kinds of expertise on civil and commercial disputes.',
    who: 'Who does it',
    revi: 'Revision',
    acco: 'Accounting',
    'copm regi': 'Company Registration',
    'busi plan': 'Business Plan',
    appr: 'Appraisal',
    expertise: 'Expertise',
    'inve coun': 'Investment Counseling',
    'brok advi': 'Brokerage Advice',
    'read more': 'Read more',
    'trans 1': 'Here you can see our transparency reports',
    how: 'How',
    'add post': 'Add Post',
    submit: 'Submit',
    'cont 1': 'You want to work for and with us ?',
    'news 1': 'Notifications, news and events',
    'cont 2': 'Your company is in safe hands.',
    iplus: 'Ideja Plus',
    'loc 1': 'Our location',
    smf: 'Follow us on social media !',
    'how 1':
      'Through this type of service we help our clients make strategic investment decisions on where to invest their free cash, in order to diversify investment risk and maximize profit. Investment consulting involves the preparation of a number of technical and expert analysis to offer an investment in an optimal portfolio that will bring the highest profit with the minimum risk of loss.',
    'bro adv': 'Brokerage advices',
    address: 'ul. Kosta Susinov br.22 Radovis',
    'design and code': 'Designed and coded by',
    vmro1: 'Why',
    vmro2: 'When',
    vmro3: 'How',
    'kartalov petar': 'Kartalov Petar',
    'bro adv 2':
      'Because by purchasing securities (stocks and bonds) you have the opportunity to earn regular dividends and increase assets over time as a result of rising securities prices. ',
    'bro adv 3': 'Every working day. ',
    'bro adv 4':
      'Choose a broker who specializes in securities trading and advising on where to invest your money. Choose a broker and investment advisor. ',
    'bro adv 1':
      'Invest and trade in securities for a higher return on your funds through simple, fast and reliable services',
    'who 1':
      'Educated Investment Advisers, with authorization (work permit) for Investment Adviser issued by the Securities and Exchange Commission.',
    'assistant rev': 'Assistant Auditor, Authorized Accountant',
    'header-saying-1': 'Quality in everything we do !',
    'header-saying-2': 'The road to success is always under construction !',
    'ideja-logo-navbar': 'Ideja Plus Revizija & Konsalting',
    accountant: 'Licensed Accountant',
    'assistant auditor': 'Assistant Auditor, Licensed Accountant',
    'coming soon': 'Coming Soon..',
    tr2019: 'Transparency report 2019',
    placename: 'Your name..',
    placeemail: 'Email address..',
    placemessage: 'Write a message..',
    login: 'Login',
    logout: 'Logout',
    'phone ilija': ' 070/577-425',
    'title scroll': 'Scroll to top',
    postedBy1: 'Posted by',
    postedBy2: 'Janka Dimitrova',
    exit: 'Exit',
    postProject: 'Post',
    createNewsProject: 'Create a post',
    add_cert: 'Add a Certificate',
    add_trans: 'Add a Transparency Report',
    add_employee: 'Add an Employee',
    page_not_found:
      "Oops, sorry. The page you are looking for doesn't exist :(",
    delete_user_1: 'Are you sure you want to remove ',
    delete_user_2: ' from our Team ?',
    delete_user_title: 'Remove an employee',
    yes: 'Yes',
    no: 'No'
  },
  mk: {
    delete_user_title: 'Избришете Вработен',
    yes: 'Да',
    no: 'Не',
    delete_user_1: 'Дали сте сигурни дека сакате да го/ја избришете ',
    delete_user_2: ' од Нашиот Тим ?',
    page_not_found: 'Страницата што ја барате не постои :(',
    add_employee: 'Додади Вработен',
    add_trans: 'Додади Извештај',
    add_cert: 'Додади Сертификат',
    createNewsProject: 'Додади соопштение',
    exit: 'Излези',
    postProject: 'Постирај',
    postedBy1: 'Постирано од',
    postedBy2: 'Јанка Димитрова',
    'title scroll': 'Почетна',
    'phone ilija': ' 070/577-425',
    login: 'Најави се',
    logout: 'Одјави се',
    placemessage: 'Напиши порака..',
    placeemail: 'Е-маил адреса..',
    placename: 'Вашето име..',
    tr2019: 'Извештај за транспарентност 2019',
    'coming soon': 'Доаѓа наскоро..',
    accountant: 'Овластен сметководител',
    'ideja-logo-navbar': 'Идеја Плус Ревизија & Консалтинг',
    vmro1: 'Зошто',
    vmro2: 'Кога',
    vmro3: 'Како',
    'bro adv 2':
      'Затоа што со купувањето на хартии од вредност (акции и обврзници) имате можност за добивање на редовни приходи (дивидента) и зголемување на средствата со тек на време како резултат на зголемувањето на цените на хартиите од вредност. ',
    'bro adv 3': 'Секој работен ден.',
    'bro adv 4':
      'Изберете брокер кој е специјализиран за тргување со хартии од вредност и за советување каде да ги инвестирате Вашите пари. Изберете брокер и инвестиционен советник.',
    'header-saying-1': 'Квалитет во сѐ што работиме !',
    'header-saying-2': 'Патот до успехот е секогаш во изградба !',
    'read more': 'Прочитај повеќе',
    'design and code': 'Дизајнирано и кодирано од',
    'kartalov petar': 'Карталов Петар',
    iplus: 'Идеја Плус',
    smf: 'Следете нѐ на социјалните мрежи !',
    address: 'ул. Коста Сусинов бр.22 Радовиш',
    submit: 'Прати',
    'loc 1': 'Нашата локација',
    'cont 1': 'Сакате да работите за и со нас ?',
    'cont 2': 'Вашата компанија е во сигурни раце.',
    'add post': 'Додади Соопштение',
    'news 1': 'Соопштенија, вести и настани',
    'trans 1': 'Овде може да ги видите нашите извештаи за транспарентност',
    revi: 'Ревизија',
    acco: 'Сметководство',
    accreditations: 'Овластувања',
    'copm regi': 'Регистрација на трговски друштва',
    'busi plan': 'Бизнис план',
    appr: 'Проценки',
    expertise: 'Вештачење',
    'inve coun': 'Инвестициско советување',
    'brok advi': 'Брокерски совети',
    'bro adv': 'Брокерски совети',
    'bro adv 1':
      'Инвестирајте и тргувајте со хартии од вредност за поголем принос на Вашите средства, преку едноставни, брзи и доверливи услуги.',
    who: 'Кој го врши',
    how: 'Како',
    'how 1':
      'Преку овој вид на услуга им помагаме на Нашите клиенти при донесувањето на стратешки инвестициони одлуки каде да ги инвестираат своите слободни парични средства, а се со цел да го диверсифицираат ризикот при вложувањето и да обезбедат максимално голем можен профит. Инвестиционото советување опфаќа изработка на голем број технички и стручни анализи за да се понуди инвестирање во оптимално портфолио кое ќе донесе најголем профит со минимален ризик од загуби.',
    'who 1':
      'Едуцирани инвестициони советници, со овластување (дозвола за работа) за инвестиционен советник издадена од Комисијата за хартии од вредност.',
    'inv couns': 'Инвестициско советување',
    'acquired cert': 'Здобиени сертификати, лиценци и признанија',
    'expertise serv': 'Вештачење',
    'expertise serv 1':
      'За нашите клиенти и по барање на Основните судови во Р. Македонија, ги вршиме сите видови вештачења за граѓански и стопански спорови.',
    'business plan title': 'Бизнис План',
    'app title serv': 'Проценки',
    'app 1': 'Работиме на проценка на капиталот на компаниите за целите за:',
    'app 2': '• продажба на цело претпријатие',
    'app 3': '• продажба или залог на поединечни средства или опрема',
    'app 4':
      'Основна главнина при основање на трговски друштва, согласно позитивните законски прописи во Р. Македонија',
    'business plan text':
      " За потребите на нашите клиенти, нашиот тим изработува Бизнис (деловен) план и Инвестициони програми, согласно Методологијата на Светска банка, Македонската банка за подршка на развојот - МБПР, Агенција за подршка на претприемаштвото - АППРМ, Национална програма за рурален развој и ИПАРД програмата, со кои се прави проценка на бизнисот и издржаноста на планираната инвестиција, а се со цел да се задоволат барањата на домашните и странските инвеститори или при барање на средства за кофинансирање на планираната инвестиција. Да се има идеја за ширење на бизнисот е креативно и амбициозно, но можноста за реализација на замислената идеја ја проверуваме преку сеопфатна и содржајно изработена 'Физибилити Студија' која ќе даде насоки за развој и успешност на компанијата.",
    'comp reg': 'Регистрација на трговски друштва',
    'comp reg 6': '• Здруженија и фондации',
    'comp reg 5': '• Трговец поединец',
    'comp reg 4': '• ЈТД',
    'comp reg 3': '• АД',
    'comp reg 2': '• ДОО и ДООЕЛ',
    'comp reg 1':
      'Применувајќи ги одредбите на Законот за трговски друштва, стручниот и одговорниот пристап кон Нашите клиенти, е изразен и преку можноста да ИДЕЈА ПЛУС е овластен регистратор за основање на:',
    'acc serv 4':
      '• евиденција на основни средства и пресметка на амортизација',
    'accounting serv': 'Сметководство',
    'acc serv 7': '• пополнување на трговски книги',
    'acc serv 6': '• изработка на ПЛТ',
    'acc serv 5': '• пресметка на сите видови даноци',
    'acc serv 3':
      '• пресметка на интегрирана бруто плата и кадровска евиденција',
    'acc serv 2': '• финансиска, материјална и архивска евиденција',
    'acc serv 1':
      'За потребите на голем број наши клиенти од повеќе дејности, ажурно водиме:',
    serv: 'Услуги',
    'serv rev 8': '• ревизија за посебни намени и др.',
    'serv rev 7': '• ревизија за исплата на авансна дивиденда ',
    'serv rev 6': '• ревизија за заштита на малцинските интереси на друштвата ',
    'serv rev 5': '• ревизија за зголемување на основната главнина на друштва ',
    'serv rev 4': '• ревизија за основање, спојување и поделба на друштва ',
    'serv rev 3':
      '• интерна ревизија за финансиски институции (банки, штедилници, инвестициони фондови)',
    'serv rev': 'Ревизија',
    'serv rev 1':
      'Ревизијата како посебна дисциплина преку која се подига кредибилитетот на информациите презентирани во финансиските извештаи, ја спроведуваме вршејќи:',
    'serv rev 2':
      '• екстерна ревизија на финансиските извештаи на средни и големи субјекти (Акционерски друштва, ДОО и ДООЕЛ)',
    'janka dimitrova': 'Јанка Димитрова',
    test11: 'Уверение за Овластен Сметководител',
    test10: 'Уверение за Инвестициски Советник',
    cert10:
      'Сертификат за Консултант за Поддршка на Мали и Средни Претпријатија',
    'accreditation specialist':
      'Овластување за Советник за Претприемништво - Специјалист',
    'accreditation general':
      'Овластување за Советник за Претприемништво - Генералист',
    'license company acc':
      'Лиценца за Работа на Друштво за Сметководствени Работи',
    'license auditor auth': 'Лиценца за Овластен Ревизор',
    'ideja revizija konsalting': 'Идеја Плус Ревизија & Консалтинг ДОО',
    'license auditing': ' Лиценца за Друштво за Ревизија',
    'license expertise': 'Лиценца за Вешто Лице',
    'qual icm': 'Квалификација за Интернационали Капитални Маркети',
    'acert ethics': 'Сертификат за Етика и Професионални Вештини',
    'dipl acc bus': 'Диплома за Сметководство и Бизнис',
    'adv acc and business': 'Напредна Диплома за Сметководство и Бизнис',
    'riste lukarov': 'Ристе Лукаров',
    phone7: 'Телефон: 070/238-660',
    'acc audit': 'Овластен Сметководител, Асистент Ревизор',
    'gjurgja joseva': 'Ѓурѓа Јосева',
    phone6: ' 072/208-073',
    'eftimija dimitrova': 'Ефтимија Димитрова',
    acs: 'American College Скопје',
    'assistant auditor only': 'Асистент Ревизор',
    'kamelija trajanova': 'Камелија Трајанова',
    phone5: ' 078/316-876',
    phone4: ' 077/713-478',
    'assistant auditor': 'Асистент Ревизор, Овластен Сметководител',
    phone3: ' 072/303-312',
    cvlink: 'CV',
    'biljana camov': 'Билјана Чамов',
    'ilija short': 'Илија Митрев',
    'ilija mitrev': 'Проф. Илија Митрев',
    'bachelor economics': 'Дипломиран Економист',
    'auditor, appraiser': 'Овластен Ревизор, Овластен Проценител.',
    phone2: ' 072/208-074',
    'dragi dimitrov': 'Драги Димитров',
    email: 'Е-маил:',
    phone1: ' 072/208-072',
    author1:
      '  Овластен: Ревизор, Сметководител, Проценител, Брокер, Инвестициски Советник, Вешто Лице',
    partner: 'Партнер на ИДЕЈА ПЛУС РЕВИЗИЈА & КОНСАЛТИНГ',
    'prof dr janka': 'Проф. Др. Јанка Димитрова',
    'our team title': 'Нашиот тим',
    lang1: 'анг',
    lang2: 'мк',
    contact: 'Контакт',
    news: 'Новости',
    trans8: 'Извештај за транспарентност 2010',
    trans7: 'Извештај за транспарентност 2011',
    trans6: 'Извештај за транспарентност 2012',
    trans5: 'Извештај за транспарентност 2013',
    trans4: 'Извештај за транспарентност 2014',
    trans3: 'Извештај за транспарентност 2015',
    trans2: 'Извештај за транспарентност 2016',
    trans1: 'Извештај за транспарентност 2017',
    trans: 'Извештај за транспарентност 2018',
    icm: 'Интернационални Капитални Маркети',
    qual: 'Квалификации',
    test7: 'Уверение за ЦЕФЕ Тренер за Тренери',
    test6: 'Уверение за Работа со Хартии од Вредност',
    test5: 'Уверение за Овластен Сметководител - Ристе',
    test4: 'Уверение за Овластен Сметководител - Билјана',
    test3: 'Уверение за Овластен Сметководител - Драги',
    test2: 'Уверение за Овластен Сметководител - Јанка',
    test1: 'Уверение за Инвестициски Советник - Јанка',
    testimonials: 'Уверенија',
    acc2: 'Овластување за Советник за Претприемништво - Специјалист',
    acc1: 'Овластување за Советник за Претприемништво - Генералист',
    authorisations: 'Овластувања',
    lice4: 'Лиценца за Работа на Друштво за Сметководствени Работи',
    lice3: 'Лиценца за Овластен Ревизор',
    lice2: 'Лиценца за Друштво за Ревизија',
    lice1: 'Лиценца за Вешто Лице',
    lice: 'Лиценци',
    cert4: 'Сертификат за Поддршка на Мали и Средни Претпријатија',
    cert3: 'Сертификат за Етика и Професионални Вештини',
    cert2: 'Сертификати',
    'diploma smetkovodstvo': 'Диплома за Сметководство и Бизнис',
    adab: 'Напредна Диплома во Сметководство и Бизнис',
    diplomas: 'Дипломи',
    transparency: 'Транспарентност',
    services: 'Услуги',
    certificates: 'Сертификати',
    team: 'Тим',
    'logo title': 'Идеја Плус',
    'logo subtitle': 'ревизија & консалтинг ДОО',
    'about ideja plus': 'за идеја плус',
    'About us title': 'За Идеја Плус',
    'first para':
      'Идеја Плус Ревизија & Консалтинг ДОО Радовиш гради имиџ на квалитетна и компетентна консалтинг куќа повеќе години и тоа во сферите на финансиско - сметководствените и правни аспекти за деловните ангажирања на стопанските субјекти.',
    'second para':
      'Осумнаесет години ИДЕЈА ПЛУС РЕВИЗИЈА & КОНСАЛТИНГ ДОО работи со високи перформанси на квалитет, сигурност, навременост и точност во извршување на услугите кои ги нуди на своите клиенти. Постигнатото реноме е резултат на стручниот тим ангажиран во нашата куќа. Нашиот успех се потврдува со постојаното зголемување на бројот на задоволни корисници на нашите услуги со домашен и странски капитал. Заложбата за постојано подобрување на квалитетот и во иднина останува наша обврска.',
    blockquote:
      'Преку редовна усна и писмена комуникација и преку предавања кои ги организираме за Нашите клиенти, овозможуваме навремено информирање за сите новини од областа на економијата и правото.',
    professionals:
      'Ние како тим од професионалци сме најдоброто решение за Вашиот бизнис',
    para3:
      'Континуирано усовршување на вработените е заложба со која се гордееме, бидејќи само стручни и квалификувани вработени придонесуваат кон раст и развој на Нашата компанија и на Нашите клиенти. Тимот на идеја плус располага со лиценци за Овластен ревизор, Овластен сметководител.',
    para4:
      'ИДЕЈА ПЛУС РЕВИЗИЈА & КОНСАЛТИНГ ДОО е сигурен соработник во областа на економскиот и правен консалтинг. Комплетниот советнички тим на нашата куќа е во состојба да Ви овозможи ефикасни ревизорски, сметководствени, даночни, правни и финансиски услуги, преку потполно почитување на македонското законодавство.',
    para5:
      'Преку редовна усна и писмена комуникација и преку предавања кои ги организираме за Нашите клиенти, овозможуваме навремено информирање за сите новини од областа на економијата и правото. Активноста на ИДЕЈА ПЛУС РЕВИЗИЈА & КОНСАЛТИНГ е фокусирана и кон едукација на менаџерскиот, сметководствениот и финансиски персонал на Нашите клиенти преку:',
    para6:
      'Одржување на семинари за запознавање на клиентите со законската регулатива од областа на финансии, сметководство, даноци, трговско право, работни односи, платен промет и сл.',
    para7:
      'Одржување на специјализирани семинари и предавања за администрирање со локални даноци и такси, воведување на нови платни инструменти, примена ма Меѓународните стандарди за финансиско известување и др.'
  }
};

const I18n = createI18n(locales, resources);

export default I18n;
